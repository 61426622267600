import React from "react";
import Query from "../Query";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';

import CATEGORIES_QUERY from "../../queries/category/categories";

import './base.css';

const Nav = () => {
  const [openModal, setOpenModalInfo] = React.useState('');
  const [messageModal, setMessageModal] = React.useState('');

  const closeModalInfo = () => {
    setOpenModalInfo(false);
  };

  const openModalInfo = (type) => {
    let message;
    switch (type) {
      case 'S':
        message = `
          <div class="modal-img">
            <iframe src="https://giphy.com/embed/RSEuJxiw2H24U" width="100%" height="100%" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
          </div>
          <div>
            <p>All purchases over $90.00 includes shipping</p>
          </div>
        `;
        break;
      case 'F':
        message = `
          <div class="modal-img">
            <iframe src="https://giphy.com/embed/GdJz3mScUhC5W" width="100%" height="100%" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
          </div>
          <div>
            <p>Reliable Shipping</p>
          </div>
        `;
        break;
      case 'D':
        message = `
          <div class="modal-img">
            <iframe src="https://giphy.com/embed/3o6Zt0wevnhZcEIFRC" width="100%" height="100%" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
          </div>
          <div>
            <p>Discrete shipping</p>
          </div>
        `;
        break;
      case 'P':
        message = `
          <div class="modal-img">
            <iframe src="https://giphy.com/embed/RSEuJxiw2H24U" width="100%" height="100%" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
          </div>
          <div>
            <p>All purchases over $90.00 includes shipping</p>
          </div>
        `;
        break;
      default:
        break;
    }
    setMessageModal(message)
    //setOpenModalInfo(true);
  };

  return (
    <div className="navbar-container">
      <Popup open={openModal} closeOnDocumentClick onClose={closeModalInfo}>
        <div className="modal backdrop navbarModal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-close" onClick={closeModalInfo}>
                keep shopping
                {/**
                 * <a className="close">
                  &times;
                </a>
                 */}
              </div>
            </div>
            <div className="modal-body">
              <h3 style={{ marginTop: -10 }}></h3>
              <div dangerouslySetInnerHTML={{__html: messageModal}} />
            </div>
          </div>
        </div>
      </Popup>
      <Query query={CATEGORIES_QUERY} id={null}>
        {({ data: { categories } }) => {
          return (
            <div>
              <nav className="uk-navbar-container" data-uk-navbar style={{ zIndex: 2, boxShadow: '1px 7px 19px 0px rgba(0,0,0,0.04)' }}>
                <div className="uk-navbar-left">
                  <ul className="uk-navbar-nav">
                    <li>
                      <Link to="/" className="menu_logo">
                        <img src={`${process.env.PUBLIC_URL}/img/maria_juana_logo_menu.svg`} alt={'logo'} height="100" />
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="uk-navbar-right">
                  <ul className="uk-navbar-nav">
                    {categories.map((category, i) => {
                      return (
                        <li key={category.id}>
                          <Link
                            to={`/category/${category.id}`}
                            className="uk-link-reset"
                          >
                            {category.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </nav>
              <div className="navbar-info" style={{ zIndex: 2, boxShadow: '1px 7px 19px 0px rgba(0,0,0,0.04)' }}>
                <div className="navbar-left">
                  <div className="navbar-info-content" onClick={()=>openModalInfo('S')}>
                    <div className="navbar-info-icon">
                      <img src={`${process.env.PUBLIC_URL}/img/icons/free-shipping.svg`} alt={'logo'} height="100" />
                    </div>
                    <div>
                      Free Shipping<br /><span>over $90.00</span>
                    </div>
                  </div>
                </div>

                <div className="navbar-left">
                  <div className="navbar-info-content" onClick={()=>openModalInfo('F')}>
                    <div className="navbar-info-icon">
                      <img src={`${process.env.PUBLIC_URL}/img/icons/fast-delivery.svg`} alt={'logo'} height="100" />
                    </div>
                    <div>
                      Fast and reliable<br /><span>U.S. Domestic / D.R. Courriers</span>
                    </div>
                  </div>
                </div>

                <div className="navbar-center">
                  <div className="navbar-info-content" onClick={()=>openModalInfo('D')}>
                    <div className="navbar-info-icon">
                      <img src={`${process.env.PUBLIC_URL}/img/icons/delivery-box.svg`} alt={'logo'} height="100" />
                    </div>
                    <div>
                      Discrete<br />Shipping
                    </div>
                  </div>
                </div>

                <div className="navbar-right">
                  <div className="navbar-info-content" onClick={()=>openModalInfo('P')}>
                    <div className="navbar-info-icon">
                      <img src={`${process.env.PUBLIC_URL}/img/icons/payment-security.svg`} alt={'logo'} height="100" />
                    </div>
                    <div>
                      Secure<br />Payment
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default Nav;
