import React from "react";
import { useQuery, refetch } from "@apollo/react-hooks";

const Query = ({ children, query, id }) => { 
  
  const { data, loading, error, refetch } = useQuery(query, {
    variables: { id: id }
  });
  refetch();

  if (loading) return null;
  if (error) return <p style={{ backgroundColor: 'white' }}>Error: {JSON.stringify(error)}</p>;
  //console.log(data);
  return children({ data });
};

export default Query;
