import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";

import ARTICLE_QUERY from "../../queries/article/article";

const Article = () => {
  let { id } = useParams();
  
  return (
    <Query query={ARTICLE_QUERY} id={id}>
      {({ data: { articles } }) => {
        let article = articles[0];
        
        return (
          <div>
            {
              article.image?.url && 
              <div
                id="banner"
                className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
                data-src={article.image.url}
                data-srcset={article.image.url}
                data-uk-img
              >
                <h1>{article.title}</h1>
              </div>
            }
            {
              !article.image?.url && 
              <h1>{article.title}</h1>
            }

            <div className="uk-section">
              <div className="uk-container uk-container-small">
                <ReactMarkdown source={article.content} />
                <p>
                  <Moment format="MMM Do YYYY">{article.published_at}</Moment>
                </p>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default Article;
