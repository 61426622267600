import './normalize.css';
import './base.scss';

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import BodyClassName from 'react-body-classname';
import Breadcrumbs from '../../components/Breadcrumbs'

const Shipping = () => {

  useEffect(() => {

    
  });

  let history = useHistory();
  let { id } = useParams();

  return (
    <BodyClassName className="shipping">
      <div>
        <Breadcrumbs title="Shipping" />
        <div className="content">
          <div>
            <ul>
              <li>
                Commonly Asked Shipping Questions
              </li>
              <li>
                What will my package look like? How will it arrive?
              </li>
              <li>
                Where do you ship from?
              </li>
              <li>
                How much does shipping cost?
              </li>
              <li>
                When will I get my package?
              </li>
              <li>
                What happens if I receive a broken product?
              </li>
              <li>
                Discreet Packaging and Shipping
              </li>
            </ul>
          </div>
          <br/>
          Here at Maria Juana Premium Gear, we understand our customer's concerns about privacy and discretion. Everything we ship will be through plain cardboard boxes, nothing to mark glass pipes or smokeware. Our shipping labels do not say Maria Juana, and we do everything we can to ensure our packages are discreet.
          <br /><br />
          As such, our packages are shipped to you in standard shipping boxes (Either a plain brown/white box or a USPS priority mail box).  Our packages ship discretely - no mention of "Maria Juana" on the packaging.
          <br /><br />
          However, "MariaJuanaRD.com" will appear on your credit card statement. We cannot change this, and we apologize in advance for any inconvenience this may cause.
          <br /><br />
          Maria Juana reserves the right to add a signature requirement to any order at our discretion for security reasons. This is to prevent fraud and underage orders. We are very serious about preventing illicit orders.
          <br /><br />
          We can not guarantee arrival dates or times. While we try our best to prevent any delays, orders may be held up due to unforeseen circumstances.
          <br /><br />
          <h2>Free Domestic Shipping</h2>
          <br />
          We offer free domestic shipping over USD$90.00, and low cost international shipping.
          <br /><br />
          International Shipping rates start at 19.95$ for International First Class, and 29.95$ for International Priority Mail. 
          <br /><br />
          We ship everywhere in the United States and the Dominican Republic as well as a handful of international countries! We ship to Australia, Canada, Columbia, Costa Rica, Denmark, Egypt, France, Germany, Ireland, Italy, The Netherlands, Singapore, Thailand, and the United Kingdom.
          <br />
          <br />
          <h2>Shipping Times and Shipping Speed</h2>
          <br />
          Please note that the shipping time does not include the processing time for packaging up and creating orders. Once your order is placed, our shipping team works hard to get that order packaged up well and sent out to you. If placed before 10am, the majority of orders are shipped same day. However, unforeseeable delays may occasionally occur, and processing times can take up to two days.
          <br /><br />
          In rare cases of holiday or special sales, processing times can be between 2-3 days. Rest assured that the increased processing time simply means our shippers need a wee bit more time to carefully tend to each and every order. Orders are not packaged up on U.S Holidays and other instances in which the postal services are closed. Shipping times do not guarantee package arrival date.
          <br /><br />
          All of our packages are shipped out via USPS, UPS, or Fedex.  We offer a variety of shipping speeds through these carriers. Tracking labels are sent out via email once the package is picked up by the shipping carrier. You will not receive a tracking number email until your package has been processed, packaged, and labeled for pick up by the postal service. You can track the status of your order using our order tracking tool here.
          <br />
          <br />
          <h2>Items Broken in Shipping</h2>
          <br />
          ALL packages that are broken in shipping will be replaced with the same piece or an equivalent piece that has been agreed upon between Maria Juana and the customer.
          <br />
          <br />
          Broken packages must be photographed and documented before a return package will be sent out.
          <br /><br />
          Maria Juana must be informed of the break within two days of the customer receiving the package.
          <br /><br />
          Please note: For all packages damaged or broken in shipping, we will remedy this issue as soon as we possibly can. Please remain calm and polite throughout the broken items replacement process, being angry about the situation will not speed up the process.
          <br /><br />
          Still have questions? Contact us for more!
          <br />
          <br />
          IMPORTANT: WE CAN ONLY ACCEPT ITEMS THAT ARE COMPLETELY UNUSED. IF AN ITEM HAS BEEN USED, WE WILL NOT ACCEPT A RETURN. NO EXCEPTIONS. 
          <br /><br />
          If for some reason you have received your product, and no longer want to keep it, please make sure to send an email to mariajuana.hello@gmail.com before sending out your return so that we can be prepared to process your return.
          <br /><br />
          Make sure to include with your return:
          <br /><br />
          <ul>
            <li>
              Your order number (this is crucial)
            </li>
            <li>
              The item (unused)
            </li>
          </ul>
          <br />
          If we do not receive an order number or invoice with the package, we are unable to determine who the product is from, and will be unable to process your return.
          <br /><br />
          For orders that the customer chooses to return, we are only able to offer another product for the returned piece.  Customers must pay shipping and handling. 
          <br />
        </div>
      </div>
    </BodyClassName>
  );
};

export default Shipping;
