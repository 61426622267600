import React, { useEffect } from "react";
import BodyClassName from 'react-body-classname';
import Query from "../Query";
import { Link } from "react-router-dom";

import CATEGORIES_QUERY from "../../queries/category/categories";

import DayPickerInput from 'react-day-picker/DayPickerInput';
// Include the locale utils designed for moment
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';


import moment from 'moment';
// Make sure moment.js has the required locale data
import 'moment/locale/en-gb';

import './base.scss';
import 'react-day-picker/lib/style.css';

const Loader = () => {
  if ( window.location.pathname !== '/'){
    // Index (home) page
    window.location.href = '/' 
  }
  // add js to home component
  useEffect(() => {
    
  }, []);

  const [isOver21, setOver21] = React.useState(undefined);
  const [age, setAge] = React.useState(undefined);
  const [ageAccepted, setAgeAccepted] = React.useState(undefined);
  let date = new Date(moment().subtract(18, 'years'));

  const checkDate = (date) =>{
    console.log(date);
    if (date){
      let age = moment().diff(date, 'years');
      console.log(age);
      if (age > 20){
        setOver21(true);
        setAge(age);
      } else {
        setOver21(false);
      }
    }

  }

  const setAgeLocal = () =>{
    setAgeAccepted(true);
    // setter
    localStorage.setItem('ageAccepted', true);
    // reload
    window.location.reload();
  }

  return (
    <div id="loader-container">
      <Query query={CATEGORIES_QUERY} id={null}>
        {({ data: { categories } }) => {
          return (
            <BodyClassName className="pre-loader">
              <main>
                {
                  isOver21 == undefined &&
                  <div className="no-age container">
                    <div style={{ width: '100%', height:0, paddingBottom:'60%', position: 'relative' }}>
                      <iframe src="https://giphy.com/embed/Lnco9DasvpSJYXJkV0" width="100%" height="100%" style={{ position:'absolute', pointerEvents: 'none' }} frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
                    </div>
                    <div className="content">
                      <h1>Are you Over 21?</h1>
                      <h2>Let's see, enter your Birthday.</h2>
                    </div>
                    <div className="input-daterange input-group" id="birthday-datepicker">
                      <div className="form-item">
                        <label>I was born on</label><span className="fontawesome-calendar"></span>
                        <DayPickerInput 
                          dayPickerProps={{
                            locale: 'us',
                            localeUtils: MomentLocaleUtils,
                          }}
                          formatDate={formatDate}
                          parseDate={parseDate}
                          value={formatDate(moment().subtract(18, 'years'))}
                          format="MM/DD/YYYY"
                          onDayChange={day => date = day } 
                        />
                        <button
                          onClick={() => checkDate(date) } 
                        >
                          Check Date
                        </button>
                      </div>
                    </div>
                  </div>
                }
                {
                  isOver21 == true &&
                  <div className="over-age container">
                    <div style={{ width: '100%', height:0, paddingBottom:'80%', position: 'relative' }}>
                      <iframe src="https://giphy.com/embed/wOLNuaaN1NIRIvSHea" width="100%" height="100%" style={{ position:'absolute', pointerEvents: 'none' }} frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
                    </div>
                    <div className="content">
                      <h1>Thank you</h1>
                      <h2>Now you can pass. 😉</h2>
                    </div>
                    <button
                          onClick={() =>  setAgeLocal() } 
                        >
                        Enter
                    </button>
                  </div>
                }
                {
                  isOver21 == false &&
                  <div className="under-age container">
                    <div style={{ width: '100%', height:0, paddingBottom:'80%', position: 'relative' }}>
                      <iframe src="https://giphy.com/embed/15aGGXfSlat2dP6ohs" width="100%" height="100%" style={{ position:'absolute', pointerEvents: 'none' }} frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
                    </div>
                    <div className="content">
                      <h1>I'm sorry, but you can't be here</h1>
                      <h2>Come back<br />when you are 21.</h2>
                    </div>
                    <button
                      onClick={() => window.location.replace('https://google.com') } 
                    >
                      Exit
                    </button>
                  </div>
                }
              </main>
            </BodyClassName>
          );
        }}
      </Query>
      {/**
       * <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>  
       * 
       */}
    </div>
  );
};

export default Loader;
