import gql from "graphql-tag";

const ORDERS_MUTATION = gql`
  mutation Order(
    $paypal_id: String,
    $date: DateTime,
    $status: String,
    $amount: Float,
    $currency_code: String,
    $payer_id: String,
    $merchant_id: String,
    $product_id: ID
  ) {
    createOrder(
      input: {
        data: {
          paypal_id: $paypal_id,
          date: $date,
          status: $status,
          amount: $amount,
          currency_code: $currency_code,
          payer_id: $payer_id,
          merchant_id: $merchant_id,
          product_id: $product_id
        }
      }
    ) {
      order{
        id
        paypal_id
        status
        product_id{
          id
          stock
        }
      }
    }
  }
`;

export default ORDERS_MUTATION;
