import gql from "graphql-tag";

const NOTIFY_MUTATION = gql`
  mutation Notify(
    $email: String!,
    $product_id: ID!
  ) {
    createReStock(
      input: {
        data: {
          email: $email,
          product: $product_id
        }
      }
    ) {
      reStock{
        id
        email
        product{
          id
          name
        }
      }
    }
  }
`;

export default NOTIFY_MUTATION;
