import './base.scss';

import React,  { useEffect } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import BodyClassName from 'react-body-classname';
import Articles from "../../components/Articles";
import Query from "../../components/Query";

import CATEGORIES_QUERY from "../../queries/category/categories";
import ARTICLES_QUERY from "../../queries/article/articles";
import { requiredFieldMessage } from 'graphql/validation/rules/ValuesOfCorrectType';

{/**
* 
* 
<div className="uk-section">
  <div className="uk-container uk-container-large">
    <h1>Strapi blog</h1>
    <Query query={ARTICLES_QUERY}>
      {({ data: { articles } }) => {
        return <Articles articles={articles} />;
      }}
    </Query>
  </div>
</div>
*/}


function printMousePos(event) {
  console.log(event.target);
}

//document.addEventListener("click", printMousePos);

var imgs = [];
const renderGridImg = async (find) => {
  
  const url = `https://api.unsplash.com/search/photos?query=${find}&per_page=10&client_id=gK52De2Tm_dL5o1IXKa9FROBAJ-LIYqR41xBdlg3X2k`;
  fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      //console.log(data);
      if (data.results.length > 0) {
        for (let i = 0; i < data.results.length; i++) {
          imgs.push(data.results[i].urls.regular);
        }
        
        return imgs;
      }
    });
  
};

const Home = () => {
  const [renderedPage, setRendered] = React.useState(undefined);
  // add js to home component
  if (renderedPage){
    let scripts = ["demo.js"]
    scripts.map((script_name)=>{
      const script = document.createElement('script');
      script.src = `/js/${script_name}`;
      script.async = true;
      document.body.appendChild(script);
      setTimeout(() => {
        if (document.getElementById('loading')){
          document.getElementById('loading').style.opacity = 0;
          setTimeout(() => {
            document.getElementById('loading').style.display = 'none';
          }, 1000);
        }
      }, 500);

      return () => {
        document.body.removeChild(script);
      }
    })

  }

  //console.log(renderedPage);

  return (
    <BodyClassName className="homepage">
      <div>
        <div id="loading">
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>  
        </div>
        <main>
          <div className="frame">
            <div className="frame__social">
              <a href="#" aria-label="social"><img src={`${process.env.PUBLIC_URL}/img/instagram.svg`} className="frame__social-behance" alt="Instagram Logo"/></a>
            </div>
          </div>
          <div className="page page--preview">
            <div className="grid-wrap">
              <div className="grid grid--layout-1">
                <div className="grid__item-wrap">
                  <div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/1.jpg)` }} ></div>
                </div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/2.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/3.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/4.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/5.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/6.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/7.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/8.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/9.jpg)` }} ></div></div>
              </div>
              <div className="grid grid--layout-2">
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/10.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/11.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/12.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/13.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/14.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/15.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/16.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/17.jpg)` }} ></div></div>
              </div>
              <div className="grid grid--layout-3">
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/2.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/3.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/4.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/5.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/6.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/7.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/8.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/9.jpg)` }} ></div></div>
              </div>
              <div className="grid grid--layout-4">
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/10.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/11.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/12.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/13.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/14.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/15.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/16.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/17.jpg)` }} ></div></div>
              </div>
              <div className="grid grid--layout-5">
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/2.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/3.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/4.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/5.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/6.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/7.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/8.jpg)` }} ></div></div>
                <div className="grid__item-wrap"><div className="grid__item" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/9.jpg)` }} ></div></div>
              </div>
              <button className="gridback"><svg width="27px" height="15px" viewBox="0 0 27 15"><path d="M1.469 6.75l-.719.719 7.938 6.937.718-.719L1.47 6.75zM8.594.531L.75 7.375l.688.688L9.28 1.218 8.594.53zM1.406 6.938v1h24.75v-1H1.406z" fill="#de6565"/></svg></button>
            </div>
          </div>
          <div className="menu-wrap">
            <div className="menu-draggable"></div>
            <Query query={CATEGORIES_QUERY} id={null}>
              {({ data: { categories } }) => {
                return (
                  <nav className="menu">
                    {categories.map((category, i) => {
                      const imgs = renderGridImg(category.name);
                      if (i == (categories.length -1)){
                        setRendered(true);
                      }
                      return (
                        <div className="menu__item" key={`cat_${i}`}>
                          <a className="menu__item-link">{ category.name }</a>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Link
                              to={`/category/${category.id}`}
                              className="menu__item-preview"
                              id={ category.id }
                            >
                            explore
                          </Link>
                          <a className="menu__item-explore">See Images</a>
                          </div>
                        </div>
                      );
                    })}
                  </nav>
                );
              }}
            </Query>
          </div>
        </main>
        <div className="cursor">
          <div className="cursor__inner cursor__inner--circle">
            <div className="cursor__side cursor__side--left"></div>
            <div className="cursor__side cursor__side--right"></div>
          </div>
        </div>
        <Helmet>
          <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"></script>
          <script src="js/imagesloaded.pkgd.min.js"></script>
          <script src="js/charming.min.js"></script>
          <script src="js/TweenMax.min.js"></script>
          <script src="js/draggabilly.pkgd.min.js"></script>
        </Helmet>
      </div>
    </BodyClassName>
  );
};

export default Home;
