import React from "react";
import Query from "../Query";
import { Link } from "react-router-dom";

import './base.css';

const Footer = () => {
  return (
    <section className="footer">
      <div className="columns">
        <div className="column">
          <div className="footer-icon">
            <img src={`${process.env.PUBLIC_URL}/img/maria_juana_logo_menu.svg`} alt={'logo'} height="100" />
          </div>
          <p className="column__text">
            Maria Juana<br/> 
            U.S. Premium Smoke Gear
          </p>
        </div>
        <div className="column">
          <p className="column__text">
            everyday: 9:00AM - 9:30PM (AST)<br/> 
            <a href="mailto:mariajuana.hello@gmail.com">Say Hello</a>
          </p>
        </div>
        <div className="column">
          <p className="column__text">
            <Link
              to={`/shipping`}
              className="block-link"
            >
              Shipping Policy
            </Link>
            <Link
              to={`/terms`}
              className="block-link"
            >
              Terms & Conditions
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
