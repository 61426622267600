import gql from "graphql-tag";

const PRODUCTS_QUERY = gql`
  query Products($id: ID!) {
    categories(where: { id: $id }) {
      id
      name
      products {
        id
        name
        introduction
        description
        stock
        price
        gif
        artist{
          id
          name
          email
          image{
            url
          }
          website
        }
        featured
        featuredImage{
          url
        }
        category{
          name
        }
        gallery{
          id
          name
          alternativeText
          url
        }
      } 
    }
  }
`;

export default PRODUCTS_QUERY;
