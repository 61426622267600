import React from "react";

import { Switch, Route } from "react-router-dom";

import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import Articles from "../Articles";
import Article from "../Article";
import Category from "../Category";
import Home from "../Home";
import Products from "../Products";
import Shipping from "../Shipping";
import Terms from "../Terms";
import Loader from '../../components/Loader';
import { Helmet } from "react-helmet";

function App() {
  
  const getAgeLocal = () =>{
    let ageLocal = localStorage.getItem('ageAccepted');
    // getter
    return ageLocal;
  }
  
  //console.log(getAgeLocal() !== 'true' );

  return (
    <div className="App">
      {
        getAgeLocal() !== 'true' &&
        <Loader />
      }
      <Nav />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/products" component={Products} exact />
        <Route path="/article/:id" component={Article} exact />
        <Route path="/category/:id" component={Products} exact />
        <Route path="/blog" component={Articles} exact />
        <Route path="/shipping" component={Shipping} exact />
        <Route path="/terms" component={Terms} exact />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
