import gql from "graphql-tag";

const RESTOCK_MUTATION = gql`
  mutation Restock(
    $id: ID!,
    $stock: Int
  ) {
    updateProduct(
      input: {
        data: {
          stock: $stock
        },
        where:{
          id: $id
        }
      }
    ) {
      product{
        id
        stock
      }
    }
  }
`;

export default RESTOCK_MUTATION;
